.avatar-md {
	width: 2.2rem;
	height: 2.2rem;
	font-size: 1rem;
}

.header {
	min-height: 65px;
	border-bottom: var(--cui-header-border-width, 1px) solid
		var(--cui-header-border-color, #0000004f);
}

#nav_list2 {
	// padding-right: 5px;
	padding: 1px;
}

.css-1s2u09g-control {
	border-color: black;
}

#headerbtn1 {
	width: 140px;
	height: 30px;
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	font-weight: 500;
	color: #fff;
	background-color: #e21913;
	border: none;
	border-radius: 45px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
}

#headerbtn1:hover {
	background-color: #e21913;
	box-shadow: 0px 15px 20px rgba(175, 41, 41, 0.4);
	color: #fff;
	transform: translateY(-7px);
}

#headerbtn2 {
	width: 140px;
	height: 30px;
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	font-weight: 500;
	color: #fff;
	background-color: #27cc8a;
	border: none;
	border-radius: 45px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
}

#headerbtn2:hover {
	background-color: #27cc8a;
	box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
	color: #fff;
	transform: translateY(-7px);
}

.input-elevated {
	font-size: 14px;
	line-height: 1.5;
	border: none;
	background: #ffffff;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
	background-repeat: no-repeat;
	background-position: 10px 10px;
	background-size: 14px 14px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.411);
	border-radius: 5px;
	padding: 0.5em 1em 0.5em 2.5em;
}

.input-elevated::placeholder {
	color: #838d99;
}

.input-elevated:focus {
	outline: none;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
}

#cb {
	height: 180px;
	overflow: auto;
}

#cb::-webkit-scrollbar {
	width: 5px;
}

/* Track */
#cb::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
#cb::-webkit-scrollbar-thumb {
	background: rgb(192, 192, 192);
}

/* Handle on hover */
#cb::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.list-group-item {
	padding-top: 3px;
	padding-bottom: 3px;
}

.right {
	float: right;
}

.search input[type="search"]::-webkit-search-decoration,
.search input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

.search input[type="search"] {
	outline: none;
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
	background: transparent url("../assets/brand/search.png") no-repeat 0 center;

	background-size: 15px;
	border: none;
	border-bottom: 3px solid transparent;
	color: transparent;
	width: 10px;
	margin: -16px 0;
	cursor: pointer;
	z-index: 5;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

.search input[type="search"]:focus {
	width: 150px;
	padding-left: 15px;
	background: none;
	border-bottom: 3px solid #333;
	color: #333;
	cursor: auto;
}

.search input[type="search"]:hover {
	border-bottom: 3px solid #333;
}

input::-webkit-input-placeholder {
	color: transparent;
}
input:focus::-webkit-input-placeholder {
	color: #333;
}
/* Firefox < 19 */
input:-moz-placeholder {
	color: transparent;
}
input:focus:-moz-placeholder {
	color: #333;
}
/* Firefox > 19 */
input::-moz-placeholder {
	color: transparent;
}
input:focus::-moz-placeholder {
	color: #333;
}
/* Internet Explorer 10 */
input:-ms-input-placeholder {
	color: transparent;
}
input:focus:-ms-input-placeholder {
	color: #333;
}

.custom-shadow {
	// box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 20%) !important;
	box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%), 0 0px 1px 0 rgb(0 0 0 / 20%) !important;
}

.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover) {
	width: 3.8rem;
}

.offcanvas.show {
	transform: none;
}

html:not([dir="rtl"]) .offcanvas-end {
	width: 45rem;
	translate: none;
}

#randomfield {
	// -webkit-text-decoration-line: line-through; /* Safari */
	// text-decoration-line: line-through;
	background-image: url("../assets/brand/captchaBG_img1.jpg");
	background-size: 150px;
	font-style: italic;
}

.progressVisualFull {
	height: 15px !important;
	overflow: hidden;
}

.progressVisualPart {
	display: flex;
	justify-content: center;
}

.modal {
	background-color: #0000006b;
}
