@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
	background: #e2e0e0;
	// display: block;
	// margin: 0;
	-webkit-font-smoothing: antialiased;
	font-family: "Roboto", Arial, sans-serif;
	line-height: 1.5em;
	color: #000000;
	font-size: 13px !important;
	font-weight: 400;
}
.header {
	padding-top: 0px;
	padding-bottom: 0px;
}
.dropdown-item {
	font-size: 13px !important;
}
.wrapper {
	width: 100%;
	@include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
	will-change: auto;
	@include transition(padding 0.15s);
	background-color: white;
}

.container-lg {
	max-width: none;
}

.form-select {
	font-size: 14px;
}

.nav {
	align-items: center;
	justify-content: center;
}

.btn1 {
	--cui-btn-border-color: #555555;
	--cui-btn-color: #555555;
	--cui-btn-hover-bg: #555555;
	--cui-btn-hover-border-color: #555555;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #555555;
	--cui-btn-active-border-color: #555555;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #555555;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}

.btn2 {
	--cui-btn-border-color: #27afe6;
	--cui-btn-color: #27afe6;
	--cui-btn-hover-bg: #27afe6;
	--cui-btn-hover-border-color: #27afe6;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #27afe6;
	--cui-btn-active-border-color: #27afe6;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #27afe6;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
	// transform: scale(0.98);
	// box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.btn.btn1.active,
.btn.btn2.active,
.btn.btn3.active,
.btn.btn4.active,
.btn.btn5.active,
.btn.btn6.active,
.btn.btn7.active,
.btn.btn8.active {
	// 	--cui-btn-border-color: #27afe6;
	// 	--cui-btn-color: #27afe6;
	// 	--cui-btn-hover-bg: #27afe6;
	// 	--cui-btn-hover-border-color: #27afe6;
	// 	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	// 	// --cui-btn-active-bg: #27afe6;
	// 	// --cui-btn-active-border-color: #27afe6;
	// 	// --cui-btn-active-color: rgba(255, 255, 255, 0.87);
	// 	// --cui-btn-disabled-color: #27afe6;
	// 	box-shadow: 6px 7px 7px grey;
	// 	transition: 0.2s all;
	// 	// transform: scale(0.98);
	// 	// --cui-btn-active-bg: #27afe6;
	// 	// --cui-btn-active-border-color: #27afe6;
	// 	// --cui-btn-active-color: rgba(255, 255, 255, 0.87);
	// 	// --cui-btn-active-box-shadow: 6px 7px 7px grey;
	box-shadow: 6px 7px 7px grey;
	// 	/* Scaling button to 0.98 to its original size */
	// 	// box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	// 	/* Lowering the shadow */
}

.btn3 {
	--cui-btn-border-color: #f06a0c;
	--cui-btn-color: #f06a0c;
	--cui-btn-hover-bg: #f06a0c;
	--cui-btn-hover-border-color: #f06a0c;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #f06a0c;
	--cui-btn-active-border-color: #f06a0c;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #f06a0c;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}

.btn4 {
	--cui-btn-border-color: #ffb848;
	--cui-btn-color: #ffb848;
	--cui-btn-hover-bg: #ffb848;
	--cui-btn-hover-border-color: #ffb848;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #ffb848;
	--cui-btn-active-border-color: #ffb848;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #ffb848;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}

.btn5 {
	--cui-btn-border-color: #852b99;
	--cui-btn-color: #852b99;
	--cui-btn-hover-bg: #852b99;
	--cui-btn-hover-border-color: #852b99;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #852b99;
	--cui-btn-active-border-color: #852b99;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #852b99;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}
.btn6 {
	--cui-btn-border-color: #3b5998;
	--cui-btn-color: #3b5998;
	--cui-btn-hover-bg: #3b5998;
	--cui-btn-hover-border-color: #3b5998;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #3b5998;
	--cui-btn-active-border-color: #3b5998;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #3b5998;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}

.btn7 {
	--cui-btn-border-color: #ff0000;
	--cui-btn-color: #ff0000;
	--cui-btn-hover-bg: #ff0000;
	--cui-btn-hover-border-color: #ff0000;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #ff0000;
	--cui-btn-active-border-color: #ff0000;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #ff0000;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}

.btn8 {
	--cui-btn-border-color: #d63d91;
	--cui-btn-color: #d63d91;
	--cui-btn-hover-bg: #d63d91;
	--cui-btn-hover-border-color: #d63d91;
	--cui-btn-hover-color: rgba(255, 255, 255, 0.87);
	--cui-btn-active-bg: #d63d91;
	--cui-btn-active-border-color: #d63d91;
	--cui-btn-active-color: rgba(255, 255, 255, 0.87);
	--cui-btn-disabled-color: #d63d91;
	box-shadow: 6px 7px 7px grey;
	display: block;
	// transition: 0.2s all;
}

// .btn:active {
// 	transform: scale(0.98);
// 	// box-shadow: 6px 7px 7px grey;
// }

.nav-item .btn {
	width: -webkit-fill-available;
}

.nav-justified .nav-item {
	flex-basis: auto;
}

.daterangepicker {
	color: #000000;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.sidebar {
	/* For browsers that do not support gradients */
	background: -webkit-linear-gradient(#f17153, #f58d63, #f1ab53);
}
.sidebar-nav {
	color: white;
}
.sidebar-nav .nav-link {
	color: white;
	padding: 8px;
	padding-left: 13px;
}

.table {
	margin-left: auto;
	margin-right: auto;
}

.d-md-flex {
	background-color: white;
}

.sidebar-nav .nav-link:hover {
	// color: black;
	background-color: #c46c4d;
}

.sidebar-nav .nav-link.active {
	// color: transparent;
	background-color: #873a1f;
}

.sidebar-toggler:hover::before {
	// content: "postfix";
	color: #e2e0e0;
	background-color: #000000;
	// background-image: url("../assets/brand/favicon.png");
	transform: rotate(0deg);
}

.sidebar-toggler::before {
	// content: "prefix";
	color: #e2e0e0;
	background-color: #000000;
	// background-image: url("../assets/brand/Kanalytics_client.png");
	transform: rotate(0deg);
}

.navbar-toggler-icon {
	width: 1.25rem;
	height: 1.25rem;
}
.Simplebar .simplebar-vertical {
	display: none !important;
}
